const Icons = {
    Koze_logo: require("../Assets/Icons/KOZE.png"),
    instagrom: require("../Assets/Icons/instagram.png"),
    signacher: require("../Assets/Icons/signacher.png"),
    locationIcon: require("../Assets/Icons/locationIcon.png"),
    Support: require("../Assets/Icons/Support.png"),
    Listing: require("../Assets/Icons/Listing-Creation.png"),
    Pricing: require("../Assets/Icons/Pricing-Optimization.png"),
    Housekeeping: require("../Assets/Icons/Housekeeping.png"),
    KOZEFooter: require("../Assets/Icons/KOZEFooter.png"),
    instagramwhite: require("../Assets/Icons/instagramwhite.png"),
    facebook: require("../Assets/Icons/facebook.png"),
    twitter: require("../Assets/Icons/twitter.png"),
    Clientsshodow: require("../Assets/Icons/Clientsshodow.png"),
    left: require("../Assets/Icons/left.png"),
    Arror: require("../Assets/Icons/Arror.png"),
    menubar: require("../Assets/Icons/menu-bar.png"),
    Call: require("../Assets/Icons/Call.png"),
    Location: require("../Assets/Icons/Location.png"),
    Email: require("../Assets/Icons/Email.png"),
    Fb: require("../Assets/Icons/Fb.png"),
    Twiter: require("../Assets/Icons/Twiter.png"),
    Insta: require("../Assets/Icons/Insta.png"),
    Koze_Logo: require("../Assets/Icons/Home_Icone/Koze_Logo.png"),
    contact: require("../Assets/Icons/Home_Icone/contact.png"),
    contactmobail: require("../Assets/Icons/Home_Icone/contactmobail.png"),
    fb: require("../Assets/Icons/Home_Icone/fb.png"),
    instagram: require("../Assets/Icons/Home_Icone/instagram.png"),
    kingdom: require("../Assets/Icons/Home_Icone/kingdom.png"),
    Menu_left: require("../Assets/Icons/Home_Icone/menu_left.png"),
    location: require("../Assets/Icons/Home_Icone/location.png"),
    searech: require("../Assets/Icons/Home_Icone/searech.png"),
    calander: require("../Assets/Icons/Home_Icone/calander.png"),
    shape: require("../Assets/Icons/Home_Icone/shape.png"),
    puls: require("../Assets/Icons/Home_Icone/puls.png"),
    line: require("../Assets/Icons/Home_Icone/line.png"),
    arroesh: require("../Assets/Icons/Home_Icone/arroesh.png"),
    thowfor: require("../Assets/Icons/Home_Icone/24_71.png"),
    conflict: require("../Assets/Icons/Home_Icone/conflict-resolution1.png"),
    resolution: require("../Assets/Icons/Home_Icone/conflictresolution.png"),
    booking: require("../Assets/Icons/Home_Icone/booking.png"),
    hotel: require("../Assets/Icons/Home_Icone/hotel.png"),
    human: require("../Assets/Icons/Home_Icone/human.png"),
    ic_bin: require("../Assets/Icons/ic_bin.png"),
    videoplay: require("../Assets/Icons/Home_Icone/videoplay.png"),
    locationblack: require("../Assets/Icons/Home_Icone/locationblack.png"),
    Star: require("../Assets/Icons/Home_Icone/Star.png"),
    in: require("../Assets/Icons/Home_Icone/in.png"),
    youtub: require("../Assets/Icons/Home_Icone/youtub.png"),
    Agle_t: require("../Assets/Icons/Home_Icone/Agle_t.png"),
    tray_angle: require("../Assets/Icons/Home_Icone/tray_angle.png"),
    black_p: require("../Assets/Imgas/StaywithUs/black_p.png"),
    Search: require("../Assets/Icons/Search.png"),
    francefag: require("../Assets/Icons/Home_Icone/francefag.png"),
    arrowdown: require("../Assets/Icons/arrowdown.png"),
    arroeshd: require("../Assets/Icons/arroesh.png"),
    newsletter: require("../Assets/Icons/newsletter.png"),
    ic_orange_close: require("../Assets/Icons/ic_orange_close.png"),
    KOZE_Logo: require("../Assets/Icons/koze_logo.jpg"),

    landing_1: require("../Assets/Icons/landing/landing_1.png"),
    landing_2: require("../Assets/Icons/landing/landing_2.png"),
    landing_3: require("../Assets/Icons/landing/landing_3.png"),
    landing_4: require("../Assets/Icons/landing/landing_4.png"),
    landing_5: require("../Assets/Icons/landing/landing_5.png"),
    landing_6: require("../Assets/Icons/landing/landing_6.png"),
    landing_7: require("../Assets/Icons/landing/landing_7.png"),
    landing_8: require("../Assets/Icons/landing/landing_8.png"),


  };
  export default Icons;
  